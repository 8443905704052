import Rails from "rails-ujs";
import Turbolinks from "turbolinks";

/* Bootstrap modules */
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown";
import "bootstrap-sass/assets/javascripts/bootstrap/modal";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip";
import "bootstrap-sass/assets/javascripts/bootstrap/popover";
import Appsignal from "@appsignal/javascript";
import "cocoon-js";

import App from "src/app";
import EditableRow from "src/editable_row";

/* Import stylesheets */
import "../stylesheets/style";

Rails.start();
Turbolinks.start();

const editableRow = new EditableRow();

document.addEventListener("turbolinks:load", () => {
  const appsignal = new Appsignal({
    key: "f0c4e8f6-8dfe-479f-adce-9957ae9eb2a1",
  });
  editableRow.start();
});

document.addEventListener("page:change", () => {
  if (window._gaq != null) {
    return _gaq.push(["_trackPageview"]);
  } else if (window.pageTracker != null) {
    return pageTracker._trackPageview();
  }
});

/*
  monkey patched variable added by a script
  inspired by : https://stackoverflow.com/questions/9267451/how-to-check-if-http-requests-are-open-in-browser
  this is for selenium testing purpose
  adds a window variable to check if there are active XHR requests
  see the call in spec/support/wait_for_ajax.rb
*/
// (function() {
//   var oldOpen = XMLHttpRequest.prototype.open;
//   window.openHTTPs = 0;
//   XMLHttpRequest.prototype.open = function(method, url, async, user, pass) {
//     window.openHTTPs++;
//     this.addEventListener("readystatechange", function() {
//         if(this.readyState == 4) {
//           window.openHTTPs--;
//         }
//       }, false);
//     oldOpen.call(this, method, url, async, user, pass);
//   }
// })();