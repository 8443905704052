//import chosen from "chosen-js";
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'clockpicker/src/clockpicker.css';
import 'clockpicker/src/clockpicker.js';

const EditableRow = class {
  start() {
    $('.EditableRow').each(function() {
      $('.sale, .purchase, .resale').on('dblclick', handleDblClick);
      $('.order__state-btn').on('click', handleClick);
      $('.sale, .purchase, .resale').on('submit', handleSubmit);
    });
  }
};

function handleDblClick(e) {
  const $tr = $(this);
  const order_id = $tr.data('id');
  const company = $tr.data('company');
  const order_type = $tr.attr('class');
  const base_url = `/companies/${company}/${order_type}s/${order_id}`;
  const params = {};
  params['search_date'] = $tr.data('searchdate');
  $.ajax({
    type: 'GET',
    url: `${base_url}/edit_row`,
    dataType: 'html',
    data: params,
  }).done(function(data) {
    let $editable = $(data);
    handlePickers($editable);
    $tr.html($editable);
  });
}

function handleSubmit(e) {
  e.preventDefault();
  let $editable = $(this);

  const $tr = $(this).closest('tr');
  const order_id = $tr.data('id');
  const company = $tr.data('company');
  const order_type = $tr.attr('class');
  const base_url = `/companies/${company}/${order_type}s/${order_id}`;

  let params = objectifyForm($editable.find('form').serializeArray());
  $.ajax({
    type: 'PUT',
    url: `${base_url}/update_row`,
    dataType: 'html',
    data: {
      ...params,
      company_id: company,
      id: order_id,
    },
  }).done(function(data) {
    let $updated = $(data);
    $updated.find('.order__state-btn').on('click', handleClick);
    handlePickers($updated);
    $tr.html($updated);
  });
}

function handleClick(e) {
  const $tr = $(this).closest('tr');
  const order_id = $tr.data('id');
  const company = $tr.data('company');
  const order_type = $tr.attr('class');
  const base_url = `/companies/${company}/${order_type}s/${order_id}`;
  const state_kind = $(this).data('state');
  const params = {};
  params[order_type] = {};
  params['search_date'] = $tr.data('searchdate');
  params[order_type][state_kind] = 'next';
  $.ajax({
    type: 'PUT',
    url: `${base_url}/update_row`,
    dataType: 'html',
    data: params,
  }).done(function(data) {
    $tr.html(data);
    $tr.find('.order__state-btn').on('click', handleClick);
  });
}

function handlePickers($editable) {
  $editable.find('.datepicker').datepicker({
    language: 'fr',
    format: 'dd/mm/yyyy',
    orientation: 'bottom',
    autoclose: true,
    todayHighlight: true,
  });

  $editable.find('.clockpicker').clockpicker({
    placement: 'bottom',
    align: 'left',
    donetext: 'Valider',
    autoclose: true,
  });

  $editable.find('.selectize').selectize();

  $editable.find('[data-toggle="popover"]').popover({
    trigger: 'hover',
  });
}

function objectifyForm(formArray) {
  var returnArray = {};
  for (var i = 0; i < formArray.length; i++) {
    returnArray[formArray[i]['name']] = formArray[i]['value'];
  }
  return returnArray;
}

export default EditableRow;
