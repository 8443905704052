import Toastr from 'toastr';
import '@selectize/selectize';
import '@selectize/selectize/dist/css/selectize.css';
import '@selectize/selectize/dist/css/selectize.bootstrap3.css';
import 'webpack-jquery-ui/sortable';
import './rails-sortable-plugin';

document.addEventListener("turbolinks:load", function() {
  $('select.multiple')
    .mousedown(function (e) {
      e.preventDefault();

      var select = this;
      var scroll = select.scrollTop;

      e.target.selected = !e.target.selected;

      setTimeout(function () {
        select.scrollTop = scroll;
      }, 0);

      $(select).focus();
    })
    .mousemove(function (e) {
      e.preventDefault();
    });

  $('.sortable').railsSortable();

  // flash
  if ($('.alert-notification').length > 0) {
    $('.alert-notification').each(function () {
      Toastr[$(this).data('type')]($(this).html(), null, {
        closeButton: true,
        progressBar: false,
        positionClass: 'toast-bottom-center',
        preventDuplicates: true,
        timeOut: 127000,
      });
    });
  }

  // popover
  $('[data-toggle="popover"]').popover({
    trigger: 'hover',
  });

  // selectize
  $('select.selectize').each(function(){
    var $select = $(this).selectize({
      // options and settings for each Selectize instance
      plugins: ['remove_button']
    });
  });
  
  // radio buttons
  $('body').on('click', '.radio-button .btn', function () {
    let parent = $(this).closest('.radio-button');
    $(parent.data('input'))
      .val($(this).data('value'))
      .change();
    $(parent)
      .find('.btn')
      .removeClass('btn-primary')
      .addClass('btn-white');
    $(this)
      .removeClass('btn-white')
      .addClass('btn-primary');
  });
  $('.radio-button').each(function (e) {
    var val = $($(this).data('input')).val();
    if (val) $(`#${val}`).click();
  });

  // datepicker
  const datePickerOptions = {
    language: 'fr',
    format: 'dd/mm/yyyy',
    orientation: 'bottom',
    autoclose: true,
    todayHighlight: true,
  };
  $('.datepicker').datepicker(datePickerOptions);

  // handle time input fields
  $('.clockpicker').clockpicker({
    placement: 'bottom',
    align: 'left',
    donetext: 'Valider',
    autoclose: true,
  });

  // observeCustomerChange()
  const thisClass = this;
  $("[id$='customer_id']").on('change', preFillAddress);

  // print preview
  const previewEl = $('#preview');
  if (previewEl.length) {
    var company_url = previewEl.data('company-name');
    var voucher_id = previewEl.data('voucher-id');
    var url = `/companies/${company_url}/vouchers/${voucher_id}/preview`;
    previewEl.load(url);
  }

  // print color_view
  const dailyRidesEl = $('#daily-rides');
  if (dailyRidesEl.length) {
    const companyUrl = dailyRidesEl.data('company-name');
    const orderCollectionSym = dailyRidesEl.data('order-collection-sym');
    const url = `/companies/${companyUrl}/${orderCollectionSym}/daily_rides/preview`;
    dailyRidesEl.load(url);
  }
});

function preFillAddress(e) {
  if (e.target) {
    const selectEl = e.target;
    const companyName = selectEl.dataset.companyname;
    const customerId = selectEl.selectedOptions[0].value;
    const url = `/addresses?addressable_type=customer&addressable_id=${customerId}`;
    const addressInputEl = selectEl
      .closest('.address-prefill-widget')
      .querySelector('.address-prefill-target');
    if (customerId != "") {
      $.ajax({
        type: 'GET',
        url: url,
        complete: function (r) {
          addressInputEl.value = r.responseText;
        }
      });
    } else {
      addressInputEl.value = "";
    }
  }
}


document.addEventListener("turbolinks:before-cache", function() {
  var selects = $('select.selectize');
  for (var i = 0; i < selects.length; i++) {
    if (selects[i].selectize !== undefined) {
      selects[i].selectize.destroy()
    }
  }
})